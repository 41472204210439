// BOOTSTRAP VARIABLE OVERIDES
$primary: #27004b;
$secondary: #dfd0f3;
$danger: #dc3545;
$info: #21b0ff;
$link-color: #0f5d84;
$line-height-base: 1.2 !default;

// CUSTOM VARIABLES
$tertiary: #a68dbe;
$level-1: #e14f4f;
$level-2: #af4f9d;
$level-3: #267dad;
$level-4: #d95195;
$neutral: #f0eee9;
$active: #21b0ff;

// GLOBAL CSS VARIABLES
:root {
  --gl-grey: #7b7286;

  --gl-level-1: #{$level-1};
  --gl-level-2: #{$level-2};
  --gl-level-3: #{$level-3};
  --gl-level-4: #{$level-4};
  --gl-level-4-bg: #{darken($level-4, 10%)};

  --gl-primary: #27004b;
  --gl-secondary: #dfd0f3;
  --gl-tertiary: #a68dbe;
  --gl-active: #21b0ff;
  --gl-danger: #dc3545;
  --gl-gold: #fcb638;
  --gl-pale-pink: #ffbcb4;
  --gl-highlight-red: #e14f4e;
  --gl-neutral: #f0eee9;

  --gl-secondary-rgb: 223, 208, 243;
  --gl-highlight-pink: #e81aca;
}

// NG-SELECT theme
@import "~@ng-select/ng-select/themes/default.theme.css";

// Video.js
@import "video.js/src/css/video-js.scss";

// BOOTSTRAP OVERRIDES
@import "scss/variables";

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons";

@import "scss/font-greycliffcf";

// Import Codox Styling
@import url("https://cdn1.codox.io/lib/2.x/css/wave.client.css");

// Breakpoints
@import "src/scss/breakpoints";

h1 {
  font-weight: 600;
}

a {
  color: #1272a6;
}

.btn-link:hover {
  color: var(--gl-tertiary);
}

html,
body {
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  background-color: #f0eeea;
  font-size: 1.6rem;
}

// Backgrounds
.bg-primary {
  background-color: var(--gl-primary) !important;
}

.bg-level-1 {
  background-color: var(--gl-level-1) !important;
}

.bg-level-2 {
  background-color: var(--gl-level-2) !important;
}

.bg-level-3 {
  background-color: var(--gl-level-3) !important;
}

.bg-level-4 {
  background-color: var(--gl-level-4-bg) !important;
}

.bg-tertiary {
  background-color: $tertiary !important;
}

.bg-neutral {
  background-color: $neutral !important;
}

.bg-danger {
  background-color: $danger !important;
}

.bg-grey {
  background-color: var(--gl-grey) !important;
}

.bg-none {
  background: none;
}

.border-secondary {
  border-color: $secondary !important;
}

.border-tertiary {
  border-color: $tertiary !important;
}

.border-highlight-pink {
  border-color: var(--gl-highlight-pink);
}

.border-warning {
  border-color: $warning !important;
}

.color-primary {
  color: $primary !important;
}

.color-secondary {
  color: $secondary !important;
}

.color-tertiary {
  color: $tertiary !important;
}

.color-level-1 {
  color: $level-1 !important;
}

.color-level-2 {
  color: $level-2 !important;
}

.color-level-3 {
  color: $level-3 !important;
}

.color-level-4 {
  color: $level-4 !important;
}

.color-gold {
  color: var(--gl-gold) !important;
}

.color-neutral {
  color: $neutral;
}

.color-success {
  color: $success;
}

.color-danger {
  color: $danger;
}

.color-warning {
  color: $warning;
}

.color-highlight-pink {
  color: var(--gl-highlight-pink);
}

.color-active,
.active {
  color: $active;
}

.color-gray {
  color: var(--bs-gray-500);
}

.color-dark-gray {
  color: var(--bs-gray-700);
}

.color-white {
  color: white;
}

// Font sizes, weights

.fs-07 {
  font-size: 0.7rem;
}

.fs-08 {
  font-size: 0.8rem;
}

.fs-09 {
  font-size: 0.9rem;
}

.fs-1 {
  font-size: 1rem;
}

.fs-11 {
  font-size: 1.1rem;
}

.fs-12 {
  font-size: 1.2rem;
}

.fs-13 {
  font-size: 1.3rem;
}

.fs-14 {
  font-size: 1.4rem;
}

.fs-15 {
  font-size: 1.5rem;
}

.fs-16 {
  font-size: 1.6rem;
}

.fs-17 {
  font-size: 1.7rem;
}

.fs-18 {
  font-size: 1.8rem;
}

.fs-19 {
  font-size: 1.9rem;
}

.fs-2,
.fs-20 {
  font-size: 2rem;
}

.fs-21 {
  font-size: 2.1rem;
}

.fs-22 {
  font-size: 2.2rem;
}

.fs-23 {
  font-size: 2.3rem;
}

.fs-24 {
  font-size: 2.4rem;
}

.fs-25 {
  font-size: 2.5rem;
}

.fs-26 {
  font-size: 2.6rem;
}

.fs-27 {
  font-size: 2.7rem;
}

.fs-28 {
  font-size: 2.8rem;
}

.fs-29 {
  font-size: 2.9rem;
}

.fs-3 {
  font-size: 3rem;
}

.fs-4 {
  font-size: 4rem;
}

.fs-41 {
  font-size: 4.1rem;
}

.fs-42 {
  font-size: 4.2rem;
}

.fs-43 {
  font-size: 4.3rem;
}

.fs-35px {
  font-size: 35px;
}

.fw-200 {
  font-weight: 200;
}

.fw-400 {
  font-weight: 400;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.form-label {
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.no-wrap {
  white-space: nowrap;
}

// CARD STYLES

.card-header {
  background-color: #ffffff;
  border: 0;
  border-radius: 0.5rem;
}

.card-title {
  font-weight: 800;
  font-size: 1.6rem;
  cursor: pointer;
}

.card-title i {
  font-size: 1.1rem;
}

.card-tools {
  font-size: 1.1rem;
  font-weight: 200;
}

.card-tools a {
  text-decoration: none;
}

.card-tools a:hover {
  text-decoration: underline;
}

.card-body::-webkit-scrollbar {
  width: 10px;
}

.card-body::-webkit-scrollbar-track {
  background: $secondary;
  border-radius: 4px;
}

.card-body::-webkit-scrollbar-thumb {
  background: var(--bs-light);
  border-radius: 4px;
  border: 1px solid $gray-400;
}

.card-body::-webkit-scrollbar-thumb:hover {
  background: $tertiary;
}

.datePickerRow {
  max-width: 350px;
  margin: 1rem 0px 0px 0px;

  width: 100%;
  &.unconstrained {
    max-width: initial;
  }

  .ngb-dp-arrow {
    width: 0rem;
  }

  .ngb-dp-navigation-select {
    padding: 1rem 0;

    .form-select {
      font-size: 1.25rem;
      margin-left: 0.5rem;
      background-position: right 0.5rem center;
    }
  }
  .ngb-dp-month {
    width: 100%;
    font-size: 1.5rem !important;

    .ngb-dp-month-name {
      font-size: 1.5rem !important;
    }
  }
  .ngb-dp-day,
  .ngb-dp-weekday,
  .ngb-dp-week-number {
    width: 14.25%;
    height: 3rem;
    display: flex;
    justify-content: center;
    &.disabled {
      color: #ccc;
      cursor: not-allowed;
    }
    &.hidden {
      display: none;
    }
  }
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-notallowed {
  cursor: not-allowed;
}

.disabled {
  pointer-events: none;
}

.noTop {
  margin-top: 0px;
}

// DATE PICKER STYLES

.datepicker-popup {
  .ngb-dp-arrow {
    width: 0rem;
  }
  .ngb-dp-navigation-select {
    padding: 0.5rem 0;
    .form-select {
      font-size: 1.6rem;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto;
      height: auto;
      width: auto;
      margin-left: 0.5rem;
      background-position: right 0.5rem center;
    }
  }
  .ngb-dp-month {
    width: 100%;
  }
  .ngb-dp-day,
  .ngb-dp-weekday,
  .ngb-dp-week-number {
    margin: 2px;
    width: 3rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal !important;
  }
  .ngb-dp-weekday {
    font-weight: 700;
  }
}

app-daterange-picker {
  margin-top: 0px !important;
}

.ngb-dp-week:nth-child(2) {
  justify-content: end;
}

// TAB STYLES
.nav-tabs {
  .nav-link {
    font-weight: 500;
    font-size: 1.7rem;
    color: var(--gl-grey);
    &.active {
      color: var(--gl-primary);
      font-weight: 600;
    }
    &.disabled {
      font-weight: 300;
    }
  }
}

// BUTTON STYLES
.btn-primary:hover {
  background-color: #420080;
}

.btn-link {
  color: var(--gl-primary);
}

.btn-delete {
  border: none;
  background: transparent;
  color: var(--gl-tertiary);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    color: var(--gl-danger);
  }
  &:disabled {
    opacity: 0.25;
  }
}

.btn-txt {
  border: none;
  background: transparent;
}

.btn-txt-delete {
  border: none;
  background: transparent;
  color: var(--gl-primary);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    color: var(--gl-danger);
  }
}

.btn-filter {
  background-color: #f1cee0;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 3.65px;
  font-weight: 800;
  &:hover {
    background-color: #f7dbea;
  }
}

.btn-tertiary {
  background-color: $tertiary;
}

.btn-neutral {
  background-color: $neutral;
}

.btn-i {
  color: #af509d;
}

// TABLE STYLES
// works with bootstrap .table
.table.gl-striped-table {
  th {
    border-color: #b2b2b2;
  }

  tr:nth-child(2n + 1) {
    td {
      background: #f2f0ec;
    }
  }
}

// Filters

.filters-label {
  font-weight: 800;
  font-size: 1.6rem;
}

.badge-item {
  background-color: white;
  border-radius: 3.65px;
  font-weight: 800;
  --bs-bg-opacity: 1;
  border: 1px solid #dfd0f3;
  color: var(--gl-primary);
  font-weight: 200;
  margin-right: 4px;
  &:hover {
    background-color: #f7dbea;
  }
  padding: 0.8rem;
}

.removable {
  cursor: pointer;
  &:hover {
    background-color: #f7dbea;
  }
}

.nofilter {
  color: grey;
  width: 100%;
  @include breakpoint(md) {
    width: auto;
    padding: 0 50px;
  }
}

.filter-wrapper {
  width: 100%;
  @include breakpoint(md) {
    width: auto;
  }
}

// remove default button styles
.btn-reset {
  background: transparent;
  border: none;
}

.btn-next {
  font-size: 2rem;
  font-weight: 700;
  padding-right: 2rem;
  padding-left: 2rem;
}

.btn-checkbox {
  border: solid 1px transparent;
  background: var(--bs-gray-300);
  border-radius: 50%;
  cursor: pointer;
  display: block;
  width: 20px;
  min-width: 20px;
  height: 20px;
  padding: 5px 0;
  position: relative;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    border: solid 1px var(--gl-primary);
  }
  &.checked {
    background: #fff;
    border-color: var(--gl-active);
    color: var(--gl-active);
    i {
      font-size: 2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.bi.bi-circle-fill {
  color: #f0eee9;
}

.form-check-input {
  border-color: #b4b7ba;
}
// BOOTSTRAP POPOVER STYLES
.popover-lg {
  max-width: 450px;
  min-width: 360px;
  .popover-header {
    background-color: var(--bs-light);
    font-size: 1.8rem;
    font-weight: 800;
  }
  .popover-body {
    font-size: 1.5rem;
  }
}

@media (max-width: 500px) {
  .popover-lg {
    max-width: 300px;
    min-width: 100px;
  }
}

.credit-option,
.category-option,
.category-title {
  .popover {
    z-index: 1100;
  }
}

.celebrations-text p {
  margin-top: 0;
  margin-bottom: 3px;
}

.no-wrap-tooltip .tooltip-inner {
  white-space: nowrap;
}

// VIDEO JS STYLES

.video-js .vjs-big-play-button {
  height: 85px;
  width: 84px;
  border-radius: 99em;
  background: none !important;
  border: none;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  content: "";
  background-image: url("../src/assets/play-button.svg");
  background-repeat: no-repeat;
}

.card.edit {
  border: solid 4px var(--gl-secondary) !important;
}

// NG SELECT OVERRIDES
// Update placeholder to match bootstrap default
.ng-select {
  .ng-select-container {
    .ng-value-container .ng-placeholder {
      color: #212529;
    }
    .ng-clear-wrapper {
      line-height: 1;
      text-align: right;
      padding-bottom: 6px;
      .ng-clear {
        font-size: 34px;
        line-height: 1;
      }
    }
  }
}

.ng-select.growelab .ng-select-container {
  border-color: var(--gl-tertiary);
}

.ng-select.view-only .ng-select-container {
  cursor: default !important;
  .ng-arrow-wrapper {
    display: none;
  }
}

#resource-tags {
  .ng-select-container {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: rgba(var(--gl-secondary-rgb), 0.25);
  }
  .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value {
    background: #fff;
    border: solid 1px var(--gl-secondary);
    font-weight: 700;
    font-size: 1.2rem;
  }
}

.category-select {
  .ng-select.ng-select-single .ng-select-container {
    min-height: 33px;
    height: 33px;
    font-size: 1.4rem;
  }
}

.credit-type-select {
  .ng-select-container {
    border-color: var(--bs-border-color);
  }
}

.fr-box .fr-counter {
  color: var(--gl-grey);
}

.is-invalid .ng-select-container {
  border-color: #dc3545;
  padding-right: calc(1.2em + 1.2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3em + 0.3rem) center;
  background-size: calc(0.6em + 0.6rem) calc(0.6em + 0.6rem);
}

// modal styling

.resource-share-modal {
  .modal-content {
    height: 85vh;
    overflow-y: auto;
  }
}

.modal-header {
  .modal-title {
    font-weight: 500;
  }
  button {
    position: absolute;
    right: 15px;
  }
}

.rounded-content-box {
  border: solid 1px #dee2e6;
  border-radius: 6px;
  padding: 16px;
}

.testing-box {
  border: dotted 2px red;
  border-radius: 8px;
  margin: 10px 0;
  padding: 20px;
  font-size: 1.2rem;
  &.hidden {
    display: none;
  }
}
.dev-custom-backdrop {
  background-color: red;
}

// froala

.fr-placeholder {
  padding: 6px 8px !important;
  line-height: 22.4px !important;
  font-size: 1.4rem !important;
  color: #6c757d !important;
  font-family: $font-family-sans-serif !important;
}

.fr-element p {
  font-family: $font-family-sans-serif;
  font-size: 1.4rem;
  color: #212529;
}

.fr-popup.fr-active {
  z-index: 1056 !important;
}

span.fr-emoticon.fr-emoticon-img {
  background-repeat: no-repeat !important;
  font-size: inherit;
  height: 1em;
  width: 1em;
  min-height: 20px;
  min-width: 20px;
  display: inline-block;
  margin: -0.1em 0.1em 0.1em;
  line-height: 1;
  vertical-align: middle;
}

.temp-vid-button {
  border: solid 1px #ccc;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 666px;
  height: 355px;
  margin: 50px auto;
}

.builder-strand-level-content {
  border: 1px solid #ced4da;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-background-clip: padding;
  background-clip: padding-box;
  .fr-element {
    padding: 5px 8px;
    line-height: 1.6;
  }
}

.froala-toolbar-container {
  position: absolute;
  z-index: 2;
  left: 5px;
  top: 11px;
  .fr-toolbar {
    border-radius: 0.5rem !important;
    border: none;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  }
  .fr-btn {
    margin: 0 !important;
    height: 35px !important;
    .fr-svg {
      margin: 0 5px !important;
      width: 20px !important;
    }
  }
  li {
    font-size: 14px !important;
    .fr-command {
      padding: 0 10px !important;
    }
  }
  .fr-layer {
    margin: 10px !important;
    .fr-checkbox-line {
      font-size: 13px !important;
    }
    .fr-command {
      font-size: 14px !important;
    }
    .fr-input-line {
      input {
        height: 45px !important;
      }
      label {
        top: 20px;
        font-size: 13px;
      }
    }
  }
}

// Bootstrap carousel

.carousel {
  position: unset;
  .active {
    color: black;
  }
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: var(--gl-primary);
  width: 2.2rem;
  height: 3rem;
}

.notification-content {
  .carousel {
    width: 86%;
    margin: auto;
  }
  .carousel-control-next,
  .carousel-control-prev {
    width: unset;
  }
}

// Bootstrap progress bar
.evidence-progress-bar .text-bg-info {
  background-color: #6eadcf !important;
}

// Safehtml no-margin

.no-margin {
  & p,
  & blockquote {
    margin: 0;
  }
}

// iframe

.resource-content iframe {
  width: 100%;
  height: 50vh;
}

// Calendar

.fc-h-event .fc-event-title {
  text-wrap: wrap;
  font-weight: bold;
}

// Alerts

.alert {
  width: 100%;
  margin: auto;
  text-align: center;
  color: black;
}

.alert-primary {
  background-color: $primary;
  color: white;
  .btn-close {
    filter: invert(1) grayscale(100%) brightness(200%);
  }
}

.alert-secondary {
  background-color: $secondary;
}

.alert-tertiary {
  background-color: $tertiary;
}

.alert-neutral {
  background-color: $neutral;
}

.alert-danger {
  background-color: $danger;
  color: white;
  .btn-close {
    filter: invert(1) grayscale(100%) brightness(200%);
  }
}

.alert-info {
  background-color: $info;
}

.alert-location {
  position: fixed;
  width: 40%;
  z-index: 1011;
  &.alert-top-center {
    top: 0;
    left: 30%;
  }
  &.alert-bottom-center {
    bottom: 0;
    left: 30%;
  }
  &.alert-center {
    top: 45%;
    left: 30%;
  }
  &.alert-top-left {
    top: 0;
    left: 10%;
  }
  &.alert-top-right {
    top: 0;
    right: 10%;
  }
  &.alert-bottom-left {
    bottom: 0;
    left: 10%;
  }
  &.alert-bottom-right {
    bottom: 0;
    right: 10%;
  }
}

// SETTINGS -> ROSTERS -> USER BULK ACTIONS
.rosters-page {
  .ng-select.bulk-action {
    .ng-select-container {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .ng-select.secondary-action {
    .ng-select-container {
      border-radius: 0;
    }
  }

  .assigned-coach .spacer {
    height: 0;
  }
}

// IMPLEMENTATION PLAN STATUSES
.status-pill {
  border-radius: 6px;
  font-size: 1.2rem;
  font-weight: 800;
  padding: 0.3rem 0.6rem;
  text-align: center;

  &.completed {
    background-color: var(--gl-primary);
    color: white;
  }
  &.canceled {
    background-color: var(--gl-level-4);
    color: white;
  }
  &.atrisk {
    background-color: var(--gl-gold);
  }
  &.offtrack {
    background-color: var(--gl-level-2);
    color: white;
  }
  &.ontrack {
    background-color: var(--gl-active);
  }
  &.notstarted {
    background-color: var(--gl-neutral);
  }
}

// PAGINATION

.catalog-pagination {
  .page-link {
    border: none;
    color: black;
    font-weight: bold;
    border-radius: 50% !important;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      color: var(--gl-secondary);
    }
  }
  .page-item.active {
    .page-link {
      background-color: var(--gl-secondary);
    }
  }
  .disabled {
    display: none;
  }
}
